var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[_c('el-row',{staticClass:"row-bg",attrs:{"gutter":20,"justify":"space-around"}},[_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Cancellation Number")]),_c('el-form-item',{attrs:{"prop":"cancellation_number"}},[_c('v-input',{model:{value:(_vm.formData.cancellation_number),callback:function ($$v) {_vm.$set(_vm.formData, "cancellation_number", $$v)},expression:"formData.cancellation_number"}})],1)],1)]),_c('v-row-col',[_c('div',{staticClass:"form-item "},[_c('span',[_vm._v("Cancellation ")]),_c('el-form-item',{attrs:{"prop":"cancellation_file_id"}},[_c('file-container',{attrs:{"edit":true,"fileData":{
                url: _vm.formData.cancellation_file_url,
                client_name: _vm.formData.cancellation_file_name
              },"otherData":{
                idKey: 'cancellation_file_id',
                urlKey: 'cancellation_file_url',
                client_name:'cancellation_file_name'
              }},on:{"delete":_vm.handlerDeleteFile,"uploadSuccess":_vm.handlerUploadSuccess}})],1)],1)])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1"},[[_c('section',[(_vm.showMotor)?_c('div',{staticClass:"hr-inline"}):_vm._e(),_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Required Document")]),_c('el-form-item',[_c('v-checkbox-group',{attrs:{"list":_vm.documentList},model:{value:(_vm.formData.required_documents),callback:function ($$v) {_vm.$set(_vm.formData, "required_documents", $$v)},expression:"formData.required_documents"}})],1)],1),_c('div',{staticClass:"flex-sb"},[_c('div',{staticClass:"form-item form-half document-file "},[_c('span',[_vm._v("Documents")]),_vm._l((_vm.formData.documents),function(item,index){return _c('div',{key:index,staticClass:"flex theme-second-blue"},[_c('i',{staticClass:"el-icon-circle-close theme-second-blue",on:{"click":function($event){return _vm.handlerDelete(index)}}}),_c('span',{staticClass:"hand ",on:{"click":function($event){return _vm.openFile(item.url)}}},[_vm._v(_vm._s(item.client_name))])])}),_c('el-form-item',[_c('v-upload',{ref:"upload",attrs:{"multiple":true,"limit":10},on:{"uploadSuccess":_vm.handlerDocumentSuccess}})],1)],2)]),_c('div',{staticClass:"flex-sb"},[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v("Full Set Doc. Received?")]),_c('div',{staticClass:"car-type-radio"},[_c('el-form-item',[_c('v-radio-group',{attrs:{"border":true,"list":_vm.yesNoOptions},model:{value:(_vm.formData.documents_received),callback:function ($$v) {_vm.$set(_vm.formData, "documents_received", $$v)},expression:"formData.documents_received"}})],1)],1)])])])]],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }