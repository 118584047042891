<template>
  <div class="info-box">
    <v-title title="Cancellation"></v-title>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        class="row-bg"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Policy Period</span>
              <el-form-item >
                <v-date-picker
                  v-model="peroid"
                  disabled
                  type="daterange"></v-date-picker>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div
              class="form-item">
              <div class="form-item ">
                <span>Cancellation Effective Date</span>
                <el-form-item prop="cancellation_effective_date">
                  <v-date-picker
                    v-model="formData.cancellation_effective_date"
                    @change="handlerComputed"
                  ></v-date-picker>
                </el-form-item>
              </div>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span>Notes</span>
              <el-form-item prop="cancellation_notes">
                <v-input
                  v-model="formData.cancellation_notes"
                  type="textarea"
                  :rows="4"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
      <slot name="form"></slot>
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col
          :same-number="24"
          :same="true">
          <v-title title="Cancellation Premium"></v-title>
          <div class="form-item form-cloumn">
            <span>Cancellation Way</span>
            <div class="car-type-radio">
              <el-form-item prop="cancellation_way_type">
                <v-radio-group
                  v-model="formData.cancellation_way_type"
                  :border="true"
                  :list="wayList"
                  @change="handlerComputed"
                >
                </v-radio-group>
              </el-form-item>
            </div>
          </div>
          <div
            v-if="formData.cancellation_way_type==2"
            class="rate-container">
            <div  class=" flex title">
              <span class="flex-content-two title">Not Exceeding</span>
              <span class="flex-content title">Refund Premium</span>
            </div>
            <div
              v-for="(item,index) in wayExceeding"
              :key="index"
              :class="item.id==formData.cancellation_way_refund_rate?'isSelect':''"
              class="flex hand"
            >
              <div class="flex-content-two flex rate-size">
                <span>{{item.month}}</span>
                <span>{{item.rate}}</span>
              </div>
              <span  class="flex-content rate-size">{{item.refund}}</span>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Original Annual Gross Premium</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.original_gross_premium"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Original Annual Premium</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.original_premium"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Original Annual IA Levy {{cancelName}}</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.original_ia_mib_ec"
                  prepend="HKD"
                  disabled/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Cancellation Effective Date</span>
              <el-form-item prop="base_premium">
                <v-date-picker
                  v-model="formData.cancellation_effective_date"
                  disabled></v-date-picker>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Refund Premium</span>
              <el-form-item prop="loading">
                <v-input
                  v-model.number="formData.refund_premium"
                  prepend="HKD"
                  :disabled="isDisable"
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <!--              <span>Refund IA Levy MIB</span>-->
              <span>{{refundName}}</span>
              <el-form-item prop="client_discount">
                <v-input
                  v-model.number="formData.refund_ia_mib_ec"
                  prepend="HKD"
                  :disabled="isDisable"
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Total</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.refund_total"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>

        <v-row-col>
          <!--          <el-checkbox-->
          <!--            v-if="formData.cancellation_way_type===3"-->
          <!--            v-model="formData.is_special"-->
          <!--            @change="handlerCheck('is_special')"></el-checkbox>-->
          <v-row-col
            :same="true"
            :same-number="24">

            <div class="form-item ">
              <span>Special Discount</span>
              <el-form-item prop="base_premium">
                <v-input
                  v-model="formData.special_discount"
                  append="%"
                  :disabled="isDisable||hasReferrId"
                  @change="handlerSelectInsurer('is_special',1)"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Refund Premium</span>
              <el-form-item prop="loading">
                <v-input
                  v-model.number="formData.refund_special_premium"
                  prepend="HKD"
                  :disabled="isDisable||hasReferrId"
                  @change="handlerSelectInsurer('is_special',2)"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <!--              <span>Refund IA Levy MIB</span>-->
              <span>{{refundName}}</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.refund_special_ia_mib_ec"
                  prepend="HKD"
                  disabled
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Total</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.refund_special_total"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>

        <v-row-col>
          <!--          <el-checkbox-->
          <!--            v-if="formData.cancellation_way_type===3"-->
          <!--            v-model="formData.is_net"-->
          <!--            @change="handlerCheck('is_net')"></el-checkbox>-->
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Net %</span>
              <el-form-item prop="net">
                <div class="flex flex-aligin">
                  <v-input
                    v-model="formData.net"
                    append="%"
                    :disabled="isDisable||!hasReferrId"
                    @change="handlerSelectInsurer('is_net',1)"></v-input>
                  <!--                  <v-checkbox-->
                  <!--                    v-if="!isDisable"-->
                  <!--                    v-model="formData.is_net"-->
                  <!--                    :selectNumber="2"-->
                  <!--                    @change="handlerSelectInsurer(2)"></v-checkbox>-->
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Refund Premium</span>
              <el-form-item prop="loading">
                <v-input
                  v-model.number="formData.refund_net_premium"
                  prepend="HKD"
                  :disabled="isDisable||!hasReferrId"
                  @change="handlerSelectInsurer('is_net',2)"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <!--              <span>Refund IA Levy MIB</span>-->
              <span>{{refundName}}</span>
              <el-form-item prop="client_discount">
                <v-input
                  v-model.number="formData.refund_net_ia_mib_ec"
                  prepend="HKD"
                  disabled
                  @change="handlerComputed"/>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Total</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.refund_net_total"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
        <v-row-col>
          <!--          <el-checkbox-->
          <!--            v-if="formData.cancellation_way_type===3"-->
          <!--            v-model="formData.is_insurer_net"-->
          <!--            @change="handlerCheck('is_insurer_net')"></el-checkbox>-->
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Insurer Net %</span>
              <el-form-item prop="insurer_net">
                <div class="flex flex-aligin">
                  <v-input
                    v-model="formData.insurer_net"
                    append="%"
                    :disabled="isDisable"
                    @change="handlerSelectInsurer('is_insurer_net',1)">
                  </v-input>
                  <!--                  <v-checkbox-->
                  <!--                    v-if="!isDisable"-->
                  <!--                    v-model="formData.is_insurer_net"-->
                  <!--                    @change="handlerSelectInsurer(1)"></v-checkbox>-->
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Refund Premium</span>
              <el-form-item prop="loading">
                <!--                <v-input-->
                <!--                  v-model.number="formData.refund_insurer_net_premium"-->
                <!--                  prepend="HKD"-->
                <!--                  :disabled="isDisable"/>-->
                <div class="flex flex-aligin">
                  <v-input
                    v-model="formData.refund_insurer_net_premium"
                    prepend="HKD"
                    :disabled="isDisable"
                    @change="handlerSelectInsurer('is_insurer_net',2)">
                  </v-input>
                  <!--                  <v-checkbox-->
                  <!--                    v-if="!isDisable"-->
                  <!--                    v-model="formData.is_insurer_net"-->
                  <!--                    :selectNumber="2"-->
                  <!--                    @change="handlerSelectInsurer(2)"></v-checkbox>-->
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>{{refundName}}</span>
              <el-form-item prop="client_discount">
                <v-input
                  v-model.number="formData.refund_insurer_net_ia_mib_ec"
                  prepend="HKD"
                  disabled
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Total</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.refund_insurer_net_total"
                  disabled
                  prepend="HKD"/>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>

      </el-row>

    </el-form>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {computedCancel} from '@api/cancellation'
import {mapState} from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'cancelForm',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    }
  },

  data(){
    return{
      peroid:'',
      formData:{
        is_edit:0,
        cancellation_way_type:'1',//1-way 1;2-way 2;3-way 3
        cancellation_effective_date:'',
        cancellation_way_refund_rate:0,////cancellation_way_type==2時傳refund percent
        refund_net_premium:'',
        refund_net_total:'',
        refund_special_total:'',
        refund_insurer_net_premium:'',
        refund_special_premium:'',
        refund_ia_mib_ec:'',
        refund_premium:'',
        original_ia_mib_ec:'',
        original_premium:'',
        original_gross_premium:'',
        insurer_net:'',
        net:'',
        special_discount:'',
        is_net:1,
        is_insurer_net:1,
        is_special:1,
        gross_premium_difference:'',
        refund_insurer_net_ia_mib_ec:'',
        refund_net_ia_mib_ec:'',
        refund_total:'',
        refund_insurer_net_total:'',
        refund_special_ia_mib_ec:'',
        cancellation_notes:''

      },
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
    order_no(){
      let {order_no=''} = this.quoteInformationForm
      return order_no
    },
    isDisable(){
      return this.formData.cancellation_way_type!==3
    },
    hasReferrId(){
      let {referral_id} =this.quoteInformationForm||{}
      return referral_id>0
    },
    productId(){
      let {product_id} =this.quoteInformationForm||{}
      return product_id
    },
    showEcHKD(){
      return [13,14,15,16,17,18].includes(this.productId)
    },
    refundName(){
      if(this.showEcHKD)
        return  'Refund IA Levy & EC Levy'
      else if(this.productId==10)
        return  'Refund IA Levy & MIB  '
      else return 'Refund IA Levy'
    },
    cancelName(){
      if(this.productId==10)
        return  ' MIB  '
      else  return  'and EC Levy'
    },
    isYear(){
      let {effective_date,expiry_date,endorsement_effective_date,policy_type} = this.quoteInformationForm
      let date1 = dayjs(expiry_date).add(1,'day')
      let year = date1.diff(effective_date,'year')
      if(policy_type==203){
        year = date1.diff(endorsement_effective_date,'year')
      }
      return year

    },
    wayList(){
      let arr = this.wayOptions
      let year = this.isYear
      if(year<1)
        arr.splice(1,1)
      return arr
    }

  },
  // watch:{
  //   'formData.cancellation_way_type':{
  //     immediate:true,
  //     handler(val){
  //       if(val===2){
  //       }
  //     }
  //   }
  // },
  created() {
    this.hanlderInit()
  },
  methods:{
    hanlderInit(){
      if(this.quoteInformationForm){
        let {cancellation_notes,premium,cancellation_way_refund_rate=80,cancellation_effective_date,is_edit=0} = this.quoteInformationForm
        if(is_edit)
          this.formData.cancellation_effective_date = cancellation_effective_date
        this.formData.cancellation_way_refund_rate = cancellation_way_refund_rate
        this.formData.is_edit = is_edit
        Object.keys(this.formData).some(key => {
          if (key in premium) {
            this.formData[key] = premium[key]
          }
        })
        let {cancellation_way_type,is_special,is_net,is_insurer_net} = premium
        if(cancellation_way_type!==2){
          // this.formData.cancellation_way_refund_rate = 80
        }
        let {effective_date,expiry_date} = this.quoteInformationForm
        this.peroid = [effective_date,expiry_date]
        this.formData.cancellation_notes= cancellation_notes||''
        this.network().computedQuotation()
      }
    },
    handlerSelectInsurer(name,num){
      this.formData[name] = num
      console.log(
        ' this.formData[name]',name, this.formData[name]
      )
      this.handlerComputed()
    },
    handlerCheck(name){
      this.formData[name] = true
    },
    hanlderRefund(id){
      this.formData.cancellation_way_refund_rate = id
      this.handlerComputed()
    },
    async submitForm(formName) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate(valid => {
          if(valid) {
            this.$emit('getFormData', formName, this.formData)
            resolve(valid)
          } else {
            reject(valid)
          }
        })
      })
    },
    handlerComputed(){

      if(this.formData.cancellation_way_type!==3){
        this.formData.is_special = 0
        this.formData.is_net =0
        this.formData.is_insurer_net = 0
      }
      // this.$refs.form.validate((valid, invalidFields) => {
      //   if (valid) {
      this.network().computedQuotation()
      //   }else{
      //   }
      // })
    },
    network() {
      return {
        computedQuotation:async ()=>{
          let params ={...this.formData,...{order_no:this.order_no,product_id:this.form.product_id}}
          let{cancellation_way_type} = params

          let { data } = await computedCancel(params)
          Object.keys(this.formData).some(key => {
            if (key in data) {
              if(cancellation_way_type==2)
              {
                this.formData[key] = data[key]
              }
              else{
                if (key!='cancellation_way_refund_rate')
                  this.formData[key] = data[key]
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.flex-inline{
  .form-item{
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    span{
      flex: 1;
    }
    .el-form-item{
      flex: 2;
    }

  }
  .hr-inline{
    margin:20px 10px;
  }
  .form-cloumn{
    display: block!important;
  }
  .rate-container{
    padding: 12px 20px;
    background: #fff;
    border-radius: 8px;
    .title{
      font-weight: bold!important;
      font-size: 14px;
      color: #718096;
    }
    .flex-content{
      flex: 1;
      min-height: 30px;
      line-height: 30px;
      font-weight: normal;
      font-size: 14px;
      color: #718096;
    }
    .flex-content-two{
      flex: 2;
      min-height: 30px;
      line-height: 30px;
      font-weight: normal;
      font-size: 14px;
      color: #718096;
      padding: 0 5px;
      span{
        flex: 1;
        word-break: break-word;
      }
    }
    .rate-size{
      word-break: break-word;
    }
    .isSelect{
      background: #E0F3FB;
    }
  }

}

</style>
